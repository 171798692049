import logo_pink from "../../assets/images/logo.png";

const ConfirmMintModal = ({ closeModal }) => {
  return (
    <div className="popup-wrap" style={{ display: "block" }}>
      <div className="dim" />
      <div className="pop-mint" id="mint">
        <strong>
          <img src={logo_pink} alt="" />
        </strong>
        <p>
          민팅이 완료되었습니다!
          <i className="br">
            민팅된 <span style={{ fontWeight: "bold" }}>포토 NFT</span>는 지갑에서 확인할 수
            있습니다.
          </i>
        </p>
        <a onClick={closeModal} className="btn-round pop-close" style={{ cursor: "pointer" }}>
          확인
        </a>
      </div>
    </div>
  );
};

export default ConfirmMintModal;
