import "./App.css";
import { clusterApiUrl } from "@solana/web3.js";
import { useState, useMemo, useEffect } from "react";

import { ConnectionProvider, WalletProvider } from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-wallets";
import { WalletModalProvider, WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import MetaplexProvider from "./components/MetaplexProvider";
import "@solana/wallet-adapter-react-ui/styles.css";
import ShowNFTs from "./components/ShowNFTs";
import {
  createDefaultAuthorizationResultCache,
  SolanaMobileWalletAdapter,
} from "@solana-mobile/wallet-adapter-mobile";
import { BrowserRouter } from "react-router-dom";
import PublicRouter from "./common/router";

function App() {
  const [address, setAddress] = useState("3ijFZcJKmp1EnDbbuaumWYvEFbztx9NRupwTXTchK9bP");
  const [nft, setNft] = useState(null);

  const [network, setNetwork] = useState(WalletAdapterNetwork.Mainnet);
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  const wallets = useMemo(
    () => [
      new SolanaMobileWalletAdapter({
        appIdentity: {
          name: "Solana Wallet Adapter App",
          uri: "https://lifefourcuts.treasuresclub.io/",
        },
        authorizationResultCache: createDefaultAuthorizationResultCache(),
      }),
      new PhantomWalletAdapter(),
    ],
    [network]
  );

  const handleChange = (event) => {
    switch (event.target.value) {
      case "devnet":
        setNetwork(WalletAdapterNetwork.Devnet);
        break;
      case "mainnet":
        setNetwork(WalletAdapterNetwork.Mainnet);
        break;
      case "testnet":
        setNetwork(WalletAdapterNetwork.Testnet);
        break;
      default:
        setNetwork(WalletAdapterNetwork.Devnet);
        break;
    }
  };

  return (
    <div>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletModalProvider>
            <MetaplexProvider>
              <PublicRouter />
            </MetaplexProvider>
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
      <div id="modal" />
    </div>
  );
}

export default App;
