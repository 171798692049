import { ConnectionProvider, useWallet, WalletProvider } from "@solana/wallet-adapter-react";
import { useEffect, useState, useRef, useMemo } from "react";
import { useMetaplex } from "../../components/useMetaplex";
import { bundlrStorage, Metaplex, walletAdapterIdentity } from "@metaplex-foundation/js";
import { clusterApiUrl, Connection } from "@solana/web3.js";
import { WalletModalProvider, WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useSearchParams, useNavigate } from "react-router-dom";
import { getMetadata, saveMintInfo } from "../../api";
import styled from "styled-components";
import classNames from "classnames";
import ClipLoader from "react-spinners/ClipLoader";

import img_main from "../../assets/images/img_main.png";
import logo from "../../assets/images/logo.png";
import logo_white from "../../assets/images/logo_white.png";
import img_duck from "../../assets/images/img_duck.png";
import img_duck_m from "../../assets/images/img_duck-m.png";
import logo_pink from "../../assets/images/logo_pink.png";
import ico_scrolldown from "../../assets/images/ico_scrolldown.png";
import ico_scrolldown_m from "../../assets/images/ico_scrolldown-m.png";
import img_photo from "../../assets/images/img_photo.png";
import img_photo_m from "../../assets/images/img_photo-m.png";

import useModal from "../../hooks/useModal";
import ConfirmMintModal from "../../components/Modal/ConfirmMint";
import { ScaleLoader } from "react-spinners";
import {
  createDefaultAuthorizationResultCache,
  SolanaMobileWalletAdapter,
} from "@solana-mobile/wallet-adapter-mobile";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-wallets";
import MetaplexProvider from "../../components/MetaplexProvider";
import { useRecoilState } from "recoil";
import { currentLangAtom } from "../../recoil/atom";
import { localeLangsUtil } from "../../utils/locale";
import i18n from "i18next";
import { Trans, useTranslation } from "react-i18next";

const connection = new Connection(clusterApiUrl("mainnet-beta"));

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const AWrapper = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 4px;

  @media (max-width: 1280px) {
    > span {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      padding: 5px !important;
    }
  }
`;

const MintBtn = styled(WalletMultiButton)`
  display: block;
  width: 474px;
  margin: 0 auto;
  border-radius: 43px;
  background: #525252;
  font-size: 50px;
  font-weight: 800;
  line-height: 93px;
  text-align: center;
  color: #fff;
  height: 93px;

  @media (max-width: 1280px) {
    height: 46px;
  }
  i {
    display: none;
  }

  img {
    display: none;
  }

  @media (max-width: 1280px) {
    width: 230px;
    font-size: 24px;
    line-height: 46px;
  }
`;

const MintPage = () => {
  const navigate = useNavigate();
  const [provider, setProvider] = useState(null);
  const [walletAvail, setWalletAvail] = useState(false);
  const [publicKey, setPublicKey] = useState("");
  const [isLogin, setIsLogin] = useState(false);
  const [checked, setChecked] = useState(false);
  const [mintLoading, setMintLoading] = useState(false);
  const [term, setTerm] = useState(false);
  const { t } = useTranslation();
  const [nftImage, setNftImage] = useState(img_photo);

  const [currentLang, setCurrentLang] = useRecoilState(currentLangAtom);

  const [metadata, setMetadata] = useState(null);
  const [network, setNetwork] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const linkId = searchParams.get("linkid");
  const { ModalPortal, openModal, closeModal } = useModal();

  const wallet = useWallet();
  const [nft, setNft] = useState(null);
  const btnRef = useRef(null);
  const { metaplex } = useMetaplex();
  const mx2 = Metaplex.make(connection)
    .use(walletAdapterIdentity(wallet))
    .use(bundlrStorage({ address: "https://devnet.bundlr.network" }));
  function isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }
  const mintGuideUrl = isMobile()
    ? "https://www.notion.so/sophiejihyun/Mobile-Web-How-to-Connect-Phantom-Wallet-d759a8eb40634e6fb792fdd1f59fc256"
    : "https://www.notion.so/sophiejihyun/PC-Web-How-to-Mint-1e488ba8985149baaebea4ecf7a9d48d";

  const onMintHandler = async () => {
    if (!checked) {
      setTerm(true);
      return;
    }
    const connection = new Connection(clusterApiUrl("mainnet-beta"));
    const mx2 = Metaplex.make(connection)
      .use(walletAdapterIdentity(wallet))
      .use(
        bundlrStorage({
          address: "https://node1.bundlr.network",
          providerUrl: "https://api.mainnet-beta.solana.com",
        })
      );
    try {
      if (wallet.connected && wallet?.publicKey && metadata && checked) {
        setMintLoading(true);
        const { uri } = await mx2.nfts().uploadMetadata(metadata);
        const { nft } = await metaplex.nfts().create({ uri: uri });
        await saveMintInfo(linkId, {
          txid: nft.mint.toBase58(),
          address: wallet.publicKey.toBase58(),
        });
        setMintLoading(false);
        openModal();
      }
    } catch (err) {
      setMintLoading(false);
      if (err.error.code === -32603) {
        return;
      }
      alert("에러가 발생했습니다.");
    }
  };

  const onToggle = () => {
    setChecked(!checked);
  };

  const bootstrap = async () => {
    try {
      if (linkId) {
        const result = await getMetadata(linkId);
        setMetadata(result?.metadata);
        setNetwork(result?.blockchain?.network);
        setNftImage(result?.metadata.image);
      }
    } catch (err) {
      navigate("/error");
    }
  };

  const getProvider = () => {
    if ("phantom" in window) {
      const provider = window.phantom?.solana;

      if (provider?.isPhantom) {
        return provider;
      }
    }

    window.open("https://phantom.app/", "_blank");
  };

  useEffect(() => {
    if (checked) {
      setTerm(false);
    }
  }, [checked]);

  useEffect(() => {
    if (!linkId) {
      navigate("/error");
      return;
    }
    bootstrap();
  }, []);
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);
  const wallets = [
    new SolanaMobileWalletAdapter({
      appIdentity: { name: "Solana Wallet Adapter App" },
      authorizationResultCache: createDefaultAuthorizationResultCache(),
    }),
    new PhantomWalletAdapter(),
  ];

  console.log(currentLang);

  return (
    <>
      <main>
        <div id="contents">
          <div className="circle-left01 circle" />
          <div className="circle-left02 circle" />
          <div className="circle-left03 circle" />
          <div className="circle-left04 circle" />
          <div className="circle-right01 circle" />
          <div className="circle-right02 circle" />
          <div
            className="lang"
            onClick={(e) => {
              console.log(e.target.innerText === "KOR &nbsp;");
              setCurrentLang(e.target.innerText === "KOR " ? "KOR" : "ENG");
              const convertLang = localeLangsUtil(e.target?.innerText);
              i18n.changeLanguage(convertLang);
            }}
          >
            <a style={{ cursor: "pointer", marginRight: "8px" }}>KOR</a>
            <a style={{ cursor: "pointer" }}> | ENG</a>
          </div>
          <section className="kv">
            <div className="inner">
              <figure>
                <img src={img_main} alt="" />
              </figure>
              <div className="text">
                <div className="logo">
                  <img src={logo} alt="" />
                </div>
                <strong>Drop the NFT!</strong>
                <p>{t(`section_01`)}</p>
                <p>{t(`section_02`)}</p>
                <p>{t(`section_03`)}</p>
                <p>
                  <Trans i18nKey="section_04" />
                </p>
                <p>
                  <Trans i18nKey="section_05" />
                </p>
                <p>{t(`section_add`)}</p>
              </div>
            </div>
            <span className="scroll">
              <span className="pc">
                <img src={ico_scrolldown} alt="" />
              </span>
              <span className="mobile">
                <img src={ico_scrolldown_m} alt="" />
              </span>
            </span>
          </section>

          <section className="how-minting">
            <h2 className="tit">How to Mint</h2>
            <ul>
              <li>
                <Trans i18nKey="section_06" />
              </li>
              <li>
                <Trans i18nKey="section_07" />
              </li>
              <li>{t(`section_08`)}</li>
              <li>{t(`section_09`)}</li>
              <li>{t(`section_10`)}</li>
              <li>
                <Trans i18nKey="section_11" />
              </li>
            </ul>
            <div className="desc">
              <p dangerouslySetInnerHTML={{ __html: t(`section_12`) }} />
              <p>- {t(`section_13`)}</p>
              <p>- {t(`section_14`)}</p>
              <p>- {t(`section_15`)}</p>
            </div>
          </section>

          <section className="connect-wallet">
            <div className="inner">
              <div className="text" style={{ fontSize: "100px" }}>
                <strong>
                  <Trans i18nKey="section_16" />
                </strong>
                <div className="connect">
                  {wallet.connected ? (
                    mintLoading ? (
                      <AWrapper className="btn-connect on">
                        <ScaleLoader
                          color="#fff"
                          loading={mintLoading}
                          cssOverride={override}
                          size={100}
                        />
                      </AWrapper>
                    ) : (
                      <AWrapper
                        className="btn-connect on"
                        onClick={onMintHandler}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        Mint
                      </AWrapper>
                    )
                  ) : (
                    <MintBtn className={classNames({ "wallet-adapter-button": false })} />

                    // <button
                    //   onClick={connectHandler}
                    //   className="btn-connect"
                    //   data-popup-target="#mint"
                    // >
                    //   Connect Wallet
                    // </button>
                  )}

                  {term && (
                    <p className="notice" style={{ width: "100%" }}>
                      {t(`section_17`)}.
                    </p>
                  )}
                  <div className="check-box">
                    <input type="checkbox" id="agree" checked={checked} />
                    <label htmlFor="agree" onClick={onToggle}>
                      <span />
                      {t(`section_18`)}
                    </label>
                  </div>
                  <a
                    href="https://www.notion.so/sophiejihyun/Drop-Drop-9808665cb6b741a48f95de6e56f29e13"
                    target="_blank"
                    className="go-policy"
                  >
                    {t(`section_19`)}
                  </a>
                  <a href={mintGuideUrl} className="go-policy" style={{ marginTop: "20px" }}>
                    {t(`section_21`)}
                  </a>
                </div>
              </div>
              <div className="photo">
                <span className="pc">
                  <img src={nftImage} alt="" />
                </span>
                <span className="mobile">
                  <img src={nftImage} alt="" />
                </span>
              </div>
            </div>
          </section>

          <section className="cont-bottom">
            <div className="inner">
              <div className="desc">
                <p>
                  - <Trans i18nKey="section_20" />{" "}
                </p>
                {currentLang === "KOR" ? (
                  <p>
                    - Drop Drop과 관련해서 궁금하신 사항이
                    <strong style={{ fontWeight: "bold" }}>
                      있다면{" "}
                      <a href="mailto:official@Treasurelabs.io" target="_blank">
                        official@Treasurelabs.io
                      </a>{" "}
                      로{" "}
                    </strong>
                    문의주세요.
                  </p>
                ) : (
                  <p>
                    - If you have any questions about Drop Drop please contact us at official
                    <a href="mailto:official@Treasurelabs.io" target="_blank">
                      <br />
                      official@Treasurelabs.io
                    </a>
                  </p>
                )}
              </div>
              <figure>
                <span className="pc">
                  <img src={img_duck} alt="" />
                </span>
                <span className="mobile">
                  <img src={img_duck_m} alt="" />
                </span>
              </figure>
            </div>
          </section>

          <footer>
            <div className="logo">
              <img src={logo_white} alt="" />
            </div>
          </footer>
        </div>

        <ModalPortal>
          <ConfirmMintModal closeModal={closeModal} />
        </ModalPortal>
      </main>
    </>
  );
};

export default MintPage;

// {
//   // address: new PublicKey(address),
//   name: "인생네컷 - SDF",
//     symbol: "TLFC",
//   description: "트레져스 클럽과 인생네컷의 어쩌고 저쩌고",
//   external_url: "https://treasuresclub.io",
//   seller_fee_basis_points: 1000,
//   image: "https://api.tilewidget.app/media/files?tag=F8E4A277",
//   attributes: [
//   {
//     trait_type: "인생네컷",
//     value: "블록체인위크 코리아",
//   },
// ],
//   collection: {
//   name: "treasure Labs X 인생네컷",
//     family: "Blockchain Week in Korea",
// },
//   properties: {
//     files: [
//       {
//         uri: "https://api.tilewidget.app/media/files?tag=F8E4A277",
//         type: "image/png",
//       },
//     ],
//       category: "image",
//     // "creators": [{
//     //     // "address": "Gy5Gb91g4vDjA2oPMRHgco3J4qqcDt9zccdDTCRhrCc8",
//     //     // "share": 100
//     // }]
//   },
// }
