import { routes } from "./routes";
import { Route, Routes } from "react-router-dom";
import HomePage from "../pages/Home";
import MintPage from "../pages/Mint";
import ErrorPage from "../pages/Error";
const PublicRouter = () => {
  const { HOME, MINT, ERROR } = routes;

  return (
    <Routes>
      <Route path='*' element={<ErrorPage />} />
      <Route path={HOME} element={<HomePage />} />
      <Route path={MINT} element={<MintPage />} />
      <Route path={ERROR} element={<ErrorPage />} />
    </Routes>
  );
};

export default PublicRouter;
