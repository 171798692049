import axios from "axios";

export const getMetadata = async (linkId) => {
  return await axios
    .get(`https://api.lifefourcuts.treasuresclub.io/apps/qrlink/${linkId}`)
    .then((resp) => resp.data.data);
};

export const saveMintInfo = async (linkId, body) => {
  return await axios
    .post(`https://api.lifefourcuts.treasuresclub.io/apps/mint/${linkId}`, body)
    .then((resp) => resp.data.data);
};
