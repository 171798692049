import { atom } from "recoil";

export const currentLangAtom = atom({
  key: "currentLanguage",
  default: "KOR",
});

// export const isPossibleMint = atom({
//   key: 'isPossibleMintAtom',
//   default: true,
// });
//
// export const contractInfo = atom({
//   key: 'contractInfo',
//   default: {
//     contractAddress: '',
//   },
// });
