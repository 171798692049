import { useLocation, useNavigate, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { routes } from "../../common/routes";

const HomePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { MINT } = routes;

  useEffect(() => {
    navigate(MINT);
  }, []);

  return <></>;
};

export default HomePage;
