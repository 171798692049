import { useMetaplex } from "./useMetaplex";
import { useState } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { Connection, clusterApiUrl, Keypair, PublicKey, LAMPORTS_PER_SOL } from "@solana/web3.js";
import {
  Metaplex,
  walletAdapterIdentity,
  keypairIdentity,
  bundlrStorage,
} from "@metaplex-foundation/js";

const connection = new Connection(clusterApiUrl("devnet"));

const ShowNFTs = (props) => {
  const wallet = useWallet();
  const [nft, setNft] = useState(null);
  const [address, setAddress] = useState("3ijFZcJKmp1EnDbbuaumWYvEFbztx9NRupwTXTchK9bP");
  const { metaplex } = useMetaplex();
  const mx2 = Metaplex.make(connection)
    .use(walletAdapterIdentity(wallet))
    .use(bundlrStorage({ address: "https://devnet.bundlr.network" }));

  const onMintHandler = async () => {
    const { uri } = await mx2.nfts().uploadMetadata({
      // address: new PublicKey(address),
      name: "인생네컷 - SDF",
      symbol: "TLFC",
      description: "트레져스 클럽과 인생네컷의 어쩌고 저쩌고",
      external_url: "https://treasuresclub.io",
      seller_fee_basis_points: 1000,
      image: "https://api.tilewidget.app/media/files?tag=F8E4A277",
      attributes: [
        {
          trait_type: "인생네컷",
          value: "블록체인위크 코리아",
        },
      ],
      collection: {
        name: "treasure Labs X 인생네컷",
        family: "Blockchain Week in Korea",
      },
      properties: {
        files: [
          {
            uri: "https://api.tilewidget.app/media/files?tag=F8E4A277",
            type: "image/png",
          },
        ],
        category: "image",
        // "creators": [{
        //     // "address": "Gy5Gb91g4vDjA2oPMRHgco3J4qqcDt9zccdDTCRhrCc8",
        //     // "share": 100
        // }]
      },
    });

    const { nft } = await metaplex.nfts().create({ uri: uri });
    console.log(nft.mint.toBase58());
  };

  const onClick = async () => {
    let myNfts = await metaplex.nfts().findAllByOwner(metaplex.identity().publicKey);
    if (!myNfts.length) {
      setNft(null);
      return;
    }
    let randIdx = Math.floor(Math.random() * myNfts.length);
    await myNfts[randIdx].metadataTask.run();
    setNft(myNfts[randIdx]);
  };

  return (
    wallet.connected && (
      <div style={{ marginTop: "50px" }}>
        <h2 onClick={onMintHandler}>
          <span
            style={{
              cursor: "pointer",
              border: "2px solid purple",
              borderRadius: "16px",
              display: "inline-block",
              padding: "10px",
            }}
          >
            Mint Button
          </span>
        </h2>
        {/*<select onChange={props.onClusterChange}>*/}
        {/*  <option value="devnet">Devnet</option>*/}
        {/*  <option value="mainnet">Mainnet</option>*/}
        {/*  <option value="testnet">Testnet</option>*/}
        {/*</select>*/}
        <div>
          <div>
            <h1>NFT Mint Address</h1>
            <div>
              <input type="text" value={nft ? nft.mint.toBase58() : ""} readOnly />
              <button onClick={onClick}>Pick Random NFT</button>
            </div>
            {nft && (
              <div>
                <h1>{nft.name}</h1>
                <img
                  src={nft.metadata.image || "/fallbackImage.jpg"}
                  alt="The downloaded illustration of the provided NFT address."
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default ShowNFTs;
