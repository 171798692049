import logo_pink from "../../assets/images/logo.png";

const ErrorPage = () => {
  return (
    <main>
      <div className="error">
        <div className="logo">
          <img src={logo_pink} alt="" />
        </div>
        <strong>404 Not Found</strong>
        <a href="mailto:official@Treasurelabs.io">official@Treasurelabs.io</a>
      </div>
    </main>
  );
};

export default ErrorPage;
